<template>
  <DetailTemplate
    custom-class="knowledge-base-detail detail-page"
    v-if="getPermission('knowledge-base:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ kbDetail.subject }}
          </h1>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        color="cyan white--text"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="editKb()"
      >
        <v-icon small left>mdi-pencil</v-icon>
        Edit
      </v-btn>
      <v-btn
        color="red lighten-1 white--text"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="deleteDialog = true"
      >
        <v-icon small left>mdi-delete</v-icon>
        Delete
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="12">
            <table class="width-100">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Knowledge Base #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ kbDetail.barcode }}
                </td>
              </tr>
              <tr v-if="false">
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Reference #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="kbDetail.reference">{{
                    kbDetail.reference
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Group
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="kbDetail.group">{{
                    kbDetail.group
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Group</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Subject
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="kbDetail.subject">{{
                    kbDetail.subject
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Subject</em></template
                  >
                </td>
              </tr>
              <tr>
                <td
                  width="200"
                  class="pb-2 font-size-18 text-capitalize"
                  valign="middle"
                >
                  Description
                </td>
                <td class="pb-2 font-size-18 text-capitalize">
                  <template v-if="kbDetail.subject">
                    <read-more
                      class="custom-read-more font-size-18 pb-1"
                      more-str="read more"
                      :text="kbDetail.description"
                      link="#"
                      less-str="read less"
                      :max-chars="1000"
                    >
                    </read-more>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No Description</em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="getPermission('knowledge-base:delete')">
        <KBDelete :delete-dialog="deleteDialog">
          <template v-slot:title>Delete Confirmation</template>
          <template v-slot:text>
            <h3 class="font-size-16 font-weight-500 delete-text">
              Once you delete
              <span class="font-weight-700">{{ kbDetail.subject }}</span
              >, you won't be able to retrieve this later. Are you sure you want
              to delete
              <span class="font-weight-700">{{ kbDetail.subject }}</span>
              ?
            </h3>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="deleteLoading"
              :loading="deleteLoading"
              class="mx-2 custom-bold-button white--text"
              color="red lighten-1"
              v-on:click="kbDeleteConfirm()"
            >
              Yes, Delete it
            </v-btn>

            <v-btn
              :disabled="deleteLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="deleteDialog = false"
            >
              No, Keep it
            </v-btn>
          </template>
        </KBDelete>
      </template>
    </template>
  </DetailTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, DELETE } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import KBDelete from "@/view/pages/partials/Delete.vue";

export default {
  mixins: [CommonMixin],
  name: "detail-knowledge-base",
  title: "Knowledge Base Detail",
  data() {
    return {
      kbId: 0,
      pageLoading: false,
      deleteDialog: false,
      deleteLoading: false,
      kbDetail: new Object({
        reference: null,
        subject: null,
        group: null,
        description: null
      })
    };
  },
  components: {
    DetailTemplate,
    KBDelete
  },
  methods: {
    kbDeleteConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, { url: `knowledge-base/${_this.kbId}` })
        .then(() => {
          _this.$router.push({ name: "admin.knowledge.base" });
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    editKb() {
      this.$router.push({
        name: "admin.knowledge.base.update",
        params: {
          id: this.kbId
        }
      });
    },
    getKB() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "knowledge-base/" + _this.kbId
        })
        .then(({ data }) => {
          _this.kbDetail = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Knowledge Base", route: "knowledge.base" },
      { title: "Detail" }
    ]);

    _this.kbId = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.kbId <= 0) {
      _this.goBack();
    }

    _this.getKB();
  }
};
</script>
